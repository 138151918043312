import Image from '@components/extra/image';
import React from 'react';

// const ReactMarkdown = require("react-markdown");

export default function FooterImage(props) {
  return (
    <div className="-mb-3">
      <Image
        {...props}
        filename="Footer-Image.png"
        style={{ width: '100%' }}
      ></Image>
    </div>
  );
}
// export const HeroImageQuery = graphql`
//   query HeroImage {
//     images: file(relativePath: { eq: "HeroImage.png" }) {
//       childImageSharp {
//         fluid(quality: 90, maxWidth: 1920) {
//           ...GatsbyImageSharpFluid_withWebp
//         }
//       }
//     }
//   }
// `;
