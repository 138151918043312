import FooterImage from '@components/FooterImage/index';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import React, { useContext, useState } from 'react';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../../context/GlobalContextProvider';
import * as styles from './Privacy.module.scss';
import toast from 'react-hot-toast';
import FooterV2 from '@components/FooterV2/index';
import Navigation from '@components/Navigation/index';

let str = '';
if (typeof window !== 'undefined') {
  let parts = window.location.hostname.split('.');
  let subdomain = parts.shift();
  let upperleveldomain = parts.join('.');

  // eslint-disable-next-line global-require
}

export default function Privacy(props) {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const [email, setEmail] = useState('');
  const [applyNowHero, setApplyNowHero] = useState(false);

  const [valid, setValid] = useState(false);
  const [myParam, setMyParam] = useState('');

  const [copySuccess, setCopySuccess] = useState('');
  const toastList = new Set();
  const MAX_TOAST = 1;

  // your function to copy here

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
    }
  };
  function sanitizeHelper(str) {
    return str.replace(/\s+/g, '-').toLowerCase();
  }
  function notify(code) {
    if (toastList.size < MAX_TOAST) {
      const id = toast.success(`Promo Code ${code} copied!`, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: 'success-toast',
        onClose: () => toastList.delete(id),
      });

      toastList.add(id);
    }
  }
  const config = {
    angle: 270,
    spread: 360,
    startVelocity: 40,
    elementCount: 140,
    dragFriction: 0.12,
    duration: 5000,
    stagger: 3,
    width: '10px',
    height: '10px',
    perspective: '500px',
    colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
  };
  const tryFreeData = [
    {
      meId: 'ME_QEATS_REVIEW_MP',
      meName: 'QReview',
      mePrice: 'Free',
      meDescription: `Implement a Social Reviews feature in QEats - a popular food-ordering app. Try this Micro-Experience to get a flavor of Crio’s signature Learn-by-Doing methodology.<br></br>`,
      meIcon: 'API.png',
      meDuration: '10 Hours',
      meSkills: ['REST API', 'cURL', 'HTTP', 'Facebook Share API', 'JSON'],
    },
    {
      meId: 'ME_QPREP_SD',
      meName: 'System Design',
      mePrice: 'Free',
      meDescription: `Ever wondered what goes on behind the scenes when you use a browser to navigate to <a href="https://www.google.com" target="_blank">www.google.com</a>? Try this Micro-Experience to get a preview of how scalable, distributed, and fault-tolerant systems are designed.`,
      meIcon: 'SystemDesign.png',
      meDuration: '10 Hours',

      meSkills: ['Client-Server Architecture', 'HTTP', 'DNS', 'Load Balancing'],
    },
  ];

  return (
    <>
      <GatsbySeo
        title="Privacy Policy"
        description="Build internship-grade projects in a real developer environment and accelerate your full stack or backend developer career with a job-ready project portfolio. Enroll and start for free."
        noindex={true}
      ></GatsbySeo>
      {/* <div className="fixed-header">
        <Header
          className="scrolled"
          theme="dark"
          logo="Crio_Dark.png"
          logoDark="Crio_Dark.png"
          linkRoute="/"
        ></Header>
      </div> */}
      <Navigation />
      <div
      // className={styles.hero}

      // image="affiliate/hero.png"
      // positionY={"-150px"}
      // style={{ color: "#000" }}
      >
        <div className="container   pt-32 pb-20">
          <div className="row ">
            <div className="col-lg-12 mt-3 ">
              <div className={`${styles.privacy}  pb-1 pt-2 pl-3 pr-3`}>
                <h5>Privacy Policy</h5>
              </div>
              <div
                className={`${styles.privacyContent} mb-4 pb-3 pt-3 pl-3 pr-3 text-sm leading-relaxed`}
              >
                We, <strong>QIFT SOLUTECH PRIVATE LIMITED</strong>, represented
                by its members, hereinafter referred to as the “Company” (where
                such expression shall, unless repugnant to the context thereof,
                be deemed to include its respective legal heirs,
                representatives, administrators, permitted successors and
                assigns.) The creator of this Privacy Policy ensures steady
                commitment to your privacy with regard to the protection of your
                invaluable information.
                <br></br>
                <br></br>
                This privacy policy contains information about the company and
                its Website{' '}
                <a
                  href="https://www.crio.do"
                  target="_blank"
                  className="underline"
                  rel="noreferrer"
                >
                  https://www.crio.do
                </a>{' '}
                (hereinafter collectively referred to as the “Platform”).{' '}
                <br></br>
                <br></br>
                In order to provide You with Our uninterrupted use of services,
                We may collect and, in some circumstances, disclose information
                about you with your permission. To ensure better protection of
                Your privacy, We provide this notice explaining Our information
                collection and disclosure policies, and the choices You make
                about the way Your information is collected and used. <br></br>
                <br></br>
                This Privacy Policy shall be in compliance with the General Data
                Protection Regulation (GDPR) in effect from May 25, 2018 and any
                and all provisions that may read to the contrary shall be deemed
                to be void and unenforceable as of that date. <br></br>
                <br></br>
                If you do not agree with the terms and conditions of our Privacy
                Policy, including in relation to the manner of collection or use
                of your information, please do not use or access the Site. If
                you have any questions or concerns regarding this Privacy
                Policy, you should contact our Customer Support Desk at{' '}
                <a
                  href="mailto:ping@criodo.com"
                  className="underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  ping@criodo.com
                </a>
                <br></br>
                <br></br>
                ANY CAPITALIZED WORDS USED HENCEFORTH SHALL HAVE THE MEANING
                ACCORDED TO THEM UNDER THIS AGREEMENT. FURTHER, ALL HEADING USED
                HEREIN ARE ONLY FOR THE PURPOSE OF ARRANGING THE VARIOUS
                PROVISIONS OF THE AGREEMENT IN ANY MANNER. NEITHER THE USER NOR
                THE CREATERS OF THIS PRIVACY POLICY MAY USE THE HEADING TO
                INTERPRET THE PROVISIONS CONTAINED WITHIN IT IN ANY MANNER.{' '}
                <br></br>
                <br></br>
                1. DEFINITIONS: <br></br>
                <br></br>
                a. “We”, “Our”, and “Us” shall mean and refer to the Platform
                and/or the Company, as the context so requires. b. “You”,
                “Your”, “Yourself”, “User”, “Buyer”, “Vendor”, “Seller” and
                “Customer” shall mean and refer to natural and legal individuals
                who use the Application or Website and who is competent to enter
                into binding contracts, as per Indian laws. c.
                “Buyers/Customers” shall refer to those willing to avail
                services for the purchase and delivery of goods through the
                Platform and “Sellers/ Vendors” shall refer to those from whom
                Buyers can purchase products through the Platform. d. “User”
                shall refer to both “Customers/Buyers” and “Seller/Vendors”. e.
                “Services” refer to providing a Platform for Customers to
                purchase goods and products offered by the variety of Sellers,
                make payment and receive delivery through the use of a single
                platform. f. “Third Parties” refer to any Application, company
                or individual apart from the User and the creator of this
                Application. g. The term “Platform” refers to the Application or
                Website which provides the Customer the facility to purchase
                products from various online Vendors and receive delivery of the
                same through the Company. h. “Personal Information” shall mean
                and refer to any personally identifiable information that We may
                collect from You such as Name, Location, Email Id, Mobile
                number, Password etc. For removal of any doubts, please refer to
                Clause 2. <br></br>
                <br></br>
                2. INFORMATION WE COLLECT <br></br>
                <br></br>
                We are committed to respecting Your online privacy. We further
                recognize Your need for appropriate protection and management of
                any Personal Information You share with us. We may collect the
                following information: a. Personal data such as, but not limited
                to, Name, Email Id, Mobile number, Password, Mailing address,
                Age, Gender, Github Handle, LinkedIn Handle etc; b.Tracking
                Information such as, but not limited to the IP address of your
                device and Device ID when connected to the Internet. This
                information may include the URL that you just came from (whether
                this URL is on the Application or not), which URL you next go to
                (whether this URL is on the Application or not), your computer
                browser information, and other information associated with your
                interaction with the Website.; c. Details of App or Website
                usage for analytics. This privacy policy also applies to data we
                collect from users who are not registered as members of this
                site, including, but not limited to, browsing behaviour, pages
                viewed etc. We also collect, and store personal information
                provided by you from time to time on the Site. We only collect
                and use such information from you that we consider necessary for
                achieving a seamless, efficient and safe experience, customized
                to your needs including: a. To enable the provision of services
                opted for by you; b. To communicate necessary account and
                service related information from time to time; c. To allow you
                to receive quality customer care services and data Collection;
                d. To comply with applicable laws, rules and regulations; Where
                any service requested by you involves a third party, such
                information as is reasonably necessary by the Company to carry
                out your service request may be shared with such third party. We
                also do use your contact information to send you offers based on
                your interests and prior activity. The Company may also use
                contact information internally to direct its efforts for service
                improvement but shall immediately delete all such information
                upon withdrawal of your consent for the same through the
                ‘unsubscribe’ button or through an email to be sent at
                ping@criodo.com. You may from time to time choose to provide
                payment related financial information (credit card, debit card,
                bank account details, billing address etc.) on the platform. We
                are committed to keeping all such sensitive data/information
                safe at all times and ensure that such data/information is only
                transacted over secure Site of approved payment gateways which
                are digitally encrypted and provide the highest possible degree
                of care available under the technology presently in use. We
                shall collect and store all such information in our internal
                Company servers for a finite period time, as long as you are a
                User of the platform and shall immediately delete all such
                information upon expiry of your User ship. We shall also delete
                all aforementioned communication upon withdrawal of User ship of
                any single Party to the communication. The Company will not use
                your financial information for any purpose other than to
                complete a transaction with you. To the extent possible, we
                provide you the option of not divulging any specific information
                that you wish for us not to collect, store or use. You may also
                choose not to use a particular service or feature on the Site
                and opt out of any non-essential communications from the
                platform. Further, transacting over the internet has inherent
                risks which can only be avoided by you following security
                practices yourself, such as not revealing account/login related
                information to any other person and informing our customer care
                team about any suspicious activity or where your account has/may
                have been compromised. At every stage prior to, during or after
                information collection, you have the right to access all
                personally identifiable information provided, rectify or alter
                all personally identifiable information provided, restrict the
                level of information to be retained as per your sole discretion
                and object to the retention, use and potential disclosure of the
                personally identifiable information. <br></br>
                <br></br>
                3. OUR USE OF YOUR INFORMATION <br></br>
                <br></br>
                The information provided by you shall be used to provide and
                improve the service for you and all users. a. For maintaining
                Internal record. b. For providing the Services c. For enhancing
                the Services provided. For more details about the nature of such
                communications, please refer to our Terms of Service. Further,
                your personal data and Sensitive Personal data may be collected
                and stored by Us for internal record. We shall disclose details
                including but not limited to Name, Location, Phone number, etc.
                of the Customers to the Sellers and Name, Phone number,
                Location, etc. of the Sellers to the Customers for the purpose
                of provision of Services to the aforementioned. We use Your
                tracking information such as IP addresses, and or Device ID to
                help identify you and to gather broad demographic information
                and make further services available to you. We will not sell,
                license or trade Your personal information. We will not share
                your personal information with others unless they are acting
                under our instructions or we are required to do so by law.
                Information collected via Our server logs includes users' IP
                addresses and the pages visited; this will be used to manage the
                web system and troubleshoot problems. We also use third-party
                analytics, tracking, optimization and targeting tools to
                understand how users engage with our Platform so that we can
                improve it and cater personalised content/ads according to their
                preferences. <br></br>
                <br></br>
                4. HOW INFORMATION IS COLLECTED <br></br>
                <br></br>
                Before or at the time of collecting personal information, we
                will identify the purposes for which information is being
                collected. If the same is not identified to you, you have the
                right to request the Company to elucidate the purpose of
                collection of said personal information, pending fulfillment of
                which you shall not be mandated to disclose any information
                whatsoever. We will collect and use your personal information
                solely with the objective of fulfilling those purposes specified
                by us, within the scope of consent of the individual concerned
                or as required by law. We will only retain personal information
                as long as necessary for the fulfillment of those purposes. We
                will collect personal information by lawful and fair means and
                with the knowledge and consent of the individual concerned.
                Personal data should be relevant to the purposes for which it is
                to be used, and, to the extent necessary for those purposes,
                should be accurate, complete, and up-to- date. <br></br>
                <br></br>
                5. COOKIES <br></br>
                <br></br>
                We use data collection devices such as “cookies” on certain
                pages of our Websites. “Cookies” are small files sited on your
                hard drive that assist us in providing customized services. We
                also offer certain features that are only available through the
                use of a “cookie”. Cookies can also help us provide information,
                which is targeted to your interests. Cookies may be used to
                identify logged in or registered users. Our Website uses session
                cookies to ensure that you have a good experience. These cookies
                contain a unique number, your 'session ID', which allows our
                server to recognize your computer and 'remember' what you've
                done on the Platform. The benefits of this are: a. You only need
                to log in once if you're navigating secure areas of the site b.
                Our server can distinguish between your computer and other
                users, so you can see the information that you have requested.
                You can choose to accept or decline cookies by modifying your
                browser settings if you prefer. This may prevent you from taking
                full advantage of the Platform. We also use various third-party
                cookies for usage, behavioral, analytics and preferences data.
                The following are the different types of Cookies used on the
                Platform: a. Authentication cookies: To identify the user and
                share the content that he or she requested for. b. Functionality
                cookies: For customized user experience and resuming past course
                progress. c. Tracking, optimization and targeting cookies: To
                capture usage metric on device, operating system, browser etc.
                to capture behavioral metrics for better content delivery. To
                cater and suggest most suited products and services. <br></br>
                <br></br>
                6. GOOGLE ANALYTICS <br></br>
                <br></br>
                We use Google Analytics to help us to understand how you make
                use of our content and work out how we can make things better.
                These cookies follow your progress through our, collecting
                anonymous data on where you have come from, which pages you
                visit, and how long you spend on the site. This data is then
                stored by Google in order to create reports. These cookies do
                not store your personal data. The information generated by the
                cookie about your use of the Website, including your IP address,
                may be transmitted to and stored by Google on servers in the
                United States. Google may use this information for the purpose
                of evaluating your use of the website, compiling reports on
                website activity for us and providing other services relating to
                website activity and internet usage. Google may also transfer
                this information to third parties where required to do so by
                law, or where such third parties process the information on
                Google's behalf. Google will not associate your IP address with
                any other data held by Google. By using this website, you
                consent to the processing of data about you by Google in the
                manner and for the purposes set out above. The Google website
                contains further information about Analytics and a copy of
                Google's privacy policy pages. <br></br>
                <br></br>
                7. EXTERNAL LINKS ON THE WEBSITE <br></br>
                <br></br>
                The Website may include advertisements, hyperlinks to other
                websites, applications, content or resources. We have no control
                over any websites or resources, which are provided by companies
                or persons other than Us. You acknowledge and agree that we are
                not responsible for the availability of any such external sites
                or resources, and do not endorse any advertising,
                services/products or other materials on or available from such
                platform or resources. You acknowledge and agree that We are not
                liable for any loss or damage which may be incurred by you as a
                result of the availability of those external sites or resources,
                or as a result of any reliance placed by you on the
                completeness, accuracy or existence of any advertising, products
                or other materials on, or available from, such websites or
                resources. These external websites and resource providers may
                have their own privacy policies governing the collection,
                storage, retention and disclosure of Your Personal Information
                that You may be subject to. We recommend that you enter the
                external Website and review their privacy policy. <br></br>
                <br></br>
                8. YOUR RIGHTS <br></br>
                <br></br>
                We are providing service that collects survey responses on
                behalf of survey Creators. If you have any questions about a
                survey, or if you want access, update or delete anything in your
                survey responses, please contact the Company directly. Unless
                subject to an exemption, you have the following rights with
                respect to your personal data: a. The right to request a copy of
                your personal data which we hold about you; b. The right to
                request for any correction to any personal data if it is found
                to be inaccurate or out of date; c. The right to withdraw Your
                consent to the processing at any time; d. The right to object to
                the processing of personal data; e. The right to lodge a
                complaint with a supervisory authority. f. The right to obtain
                information as to whether personal data are transferred to a
                third country or to an international organization. Where you
                hold an account with any of our service, you are entitled to a
                copy of all personal data which we hold in relation to you. You
                are also entitled to request that we restrict how we use your
                data in your account when you log in. <br></br>
                <br></br>
                9. CONFIDENTIALITY <br></br>
                <br></br>
                You further acknowledge that the Platform may contain
                information which is designated confidential by us and that you
                shall not disclose such information without our prior written
                consent. Your information is regarded as confidential and
                therefore will not be divulged to any third party, unless if
                legally required to do so to the appropriate authorities. We
                will not sell, share, or rent your personal information to any
                third party or use your e-mail address for unsolicited mail. Any
                emails sent by us will only be in connection with the provision
                of agreed services, and you retain sole discretion to seek for
                discontinuation of such communications at any point of time.{' '}
                <br></br>
                <br></br>
                10. OTHER INFORMATION COLLECTORS <br></br>
                <br></br>
                Except as otherwise expressly included in this Privacy Policy,
                this document only addresses the use and disclosure of
                information we collect from you. To the extent that you disclose
                your information to other parties, whether they are on our
                Platform or on other sites throughout the Internet, different
                rules may apply to their use or disclosure of the information
                you disclose to them. To the extent that we use third party
                advertisers, they adhere to their own privacy policies. Since we
                do not control the privacy policies of the third parties, you
                are subject to ask questions before you disclose your personal
                information to others. <br></br>
                <br></br>
                11. OUR DISCLOSURE OF YOUR INFORMATION <br></br>
                <br></br>
                We hosts surveys for survey creators for our platform who are
                the owners and users of your survey responses. We do not own or
                sell your responses. Anything you expressly disclose in your
                responses will be disclosed to survey creators. Please contact
                the survey creator directly to better understand how they might
                share you survey responses. We shall disclose details including
                but not limited to Name, Location, Phone number, etc. of the
                Customers to the Sellers and Name, Phone number, etc. of the
                Sellers to the Customers for the purpose of provision of
                Services to the aforementioned. Information collected will not
                be considered as sensitive if it is freely available and
                accessible in the public domain or is furnished under the Right
                to Information Act, 2005, any rules made there under or any
                other law for the time being in force. Due to the existing
                regulatory environment, we cannot ensure that all of your
                private communications and other personally identifiable
                information will never be disclosed in ways not otherwise
                described in this Privacy Policy. By way of example (without
                limiting and foregoing), we may be forced to disclose
                information to the government, law enforcement agencies or third
                parties. Therefore, although we use industry standard practices
                to protect your privacy, we do not promise, and you should not
                expect, that your personally identifiable information or private
                communications would always remain private. We do, however
                assure you that any and all disclosure of your personally
                identifiable information shall be personally intimated to you
                through an email sent to your provided email address. As a
                matter of policy, we do not sell or rent any personally
                identifiable information about you to any third party. However,
                the following describes some of the ways that your personally
                identifiable information may be disclosed: a. External Service
                Providers: There may be a number of services offered by external
                service providers that help you use our Platform. If you choose
                to use these optional services, and in the course of doing so,
                disclose information to the external service providers, and/or
                grant them permission to collect information about you, then
                their use of your information is governed by their privacy
                policy. b. Law and Order: We cooperate with law enforcement
                inquiries, as well as other third parties to enforce laws, such
                as: intellectual property rights, fraud and other rights. We can
                (and you authorize us to) disclose any information about you to
                law enforcement and other government officials as we, in our
                sole discretion, believe necessary or appropriate, in connection
                with an investigation of fraud, intellectual property
                infringements, or other activity that is illegal or may expose
                us or you to legal liability. <br></br>
                <br></br>
                12. ACCESSING, REVIEWING AND CHANGING YOUR PROFILE <br></br>
                <br></br>
                Following registration, you can review and change the
                information you submitted at the stage of registration, except
                Email ID and mobile number. An option for facilitating such
                change shall be present on the Platform and such change shall be
                facilitated by the User. If you change any information, we may
                or may not keep track of your old information. We will not
                retain in our files information you have requested to remove for
                certain circumstances, such as to resolve disputes, troubleshoot
                problems and enforce our terms and conditions. Such prior
                information shall be completely removed from our databases,
                including stored ‘back up’ systems. If you believe that any
                information, we are holding on you is incorrect or incomplete,
                or to remove your profile so that others cannot view it, the
                User needs to remediate, and promptly correct any such incorrect
                information. <br></br>
                <br></br>
                13. CONTROL OF YOUR PASSWORD <br></br>
                <br></br>
                When you sign up to become a Member, you will also be asked to
                choose a password. You are entirely responsible for maintaining
                the confidentiality of your password. It is important that you
                protect it against unauthorized access of your account and
                information by choosing your password carefully and keeping your
                password and computer secure by signing out after using our
                services. You agree not to use the account, username, email
                address or password of another Member at any time or to disclose
                your password to any third party. You are responsible for all
                actions taken with your login information and password,
                including fees. If you lose control of your password, you may
                lose substantial control over your personally identifiable
                information and may be subject to legally binding actions taken
                on your behalf. Therefore, if your password has been compromised
                for any reason, you should immediately change your password. You
                agree to notify us immediately if you suspect any consistent
                unauthorized use of your account or access to your password even
                after changing it. <br></br>
                <br></br>
                14. SECURITY <br></br>
                <br></br>
                We treat data as an asset that must be protected against loss
                and unauthorized access. We employ many different security
                techniques to protect such data from unauthorized access by
                members inside and outside the Company. We follow generally
                accepted industry standards to protect the Personal Information
                submitted to us and information that we have accessed. However,
                as effective as encryption technology is, no security system is
                impenetrable. Our Company cannot guarantee the security of our
                database, nor can we guarantee that information you provide
                won’t be intercepted while being transmitted to the Company over
                the Internet. <br></br>
                <br></br>
                15. SEVERABILTY <br></br>
                <br></br>
                Each paragraph of this privacy policy shall be and remain
                separate from and independent of and severable from all and any
                other paragraphs herein except where otherwise expressly
                indicated or indicated by the context of the agreement. The
                decision or declaration that one or more of the paragraphs are
                null and void shall have no effect on the remaining paragraphs
                of this privacy policy.
              </div>
            </div>{' '}
          </div>
        </div>
      </div>

      <FooterImage></FooterImage>
      {/* <Footer></Footer> */}
      <FooterV2 />
    </>
  );
}

// export default AboutUsPage;
